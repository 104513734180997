import React, { useEffect } from 'react';
import { initializeOrUpdateIntercomOnProd } from './utils/intercom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProgramSelector from './components/ProgramSelector';
import CreateAccount from './components/CreateAccount';
import LoanApplication from './components/LoanApplication';
import Login from './components/Login';
import VerifyEmail from './components/VerifyEmail';
import RequestPasswordReset from './components/RequestPasswordReset';
import ResetPassword from './components/ResetPassword';
import ProcessSignature from './components/ProcessSignature';
import ProcessAutopaySignature from './components/ProcessAutopaySignature';
import ProcessCheckout from './components/ProcessCheckout';
import ProcessPlaidOAuth from './components/ProcessPlaidOAuth';
import EmailPreferences from './components/EmailPreferences';

import ScrollToTop from './utils/scrollToTop';

import { useJsApiLoader } from '@react-google-maps/api';

import './index.css';
import 'tailwindcss/tailwind.css';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { enableSentryReplayIntegrationIfNotEnabled } from '.';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00684A',
    },
  },
  typography: {
    fontFamily: 'Manrope, sans-serif',
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', customPadding: true },
          style: {
            padding: '8px 32px',
          },
        },
      ],
    },
  },
});

const requiredGoogleMapsLibraries = ['places'];

const App = () => {
  useEffect(() => {
    initializeOrUpdateIntercomOnProd();

    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    
    if (accessToken && refreshToken) {
      enableSentryReplayIntegrationIfNotEnabled();
    }
  }, []);

  useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    libraries: requiredGoogleMapsLibraries,
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<ProgramSelector />} />
          <Route path="/:customApplicationLink" element={<ProgramSelector />} />
          <Route path="/start" element={<ProgramSelector />} />
          <Route path="/signup" element={<CreateAccount />} />
          <Route path="/application" element={<LoanApplication />} />
          <Route path="/process-signature" element={<ProcessSignature />} />
          <Route path="/process-autopay-signature" element={<ProcessAutopaySignature />} />
          <Route path="/process-checkout" element={<ProcessCheckout />} />
          <Route path="/plaid-oauth" element={<ProcessPlaidOAuth />} />
          <Route path="/login" element={<Login />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/request-password-reset" element={<RequestPasswordReset />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/email-preferences" element={<EmailPreferences />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
