import axios from '../utils/axiosSetup';

export const updateStudentInformation = async (applicationId, studentId, studentData) => {
  await axios.patch(`/students/${studentId}/${applicationId}`, studentData);
  const applicationResponse = await axios.get(`/loan-applications/${applicationId}`);
  return applicationResponse.data;
};

export const initiateStripeIdentityVerification = async (studentId) => {
  const response = await axios.post(`/students/${studentId}/identity-session`);
  return response.data;
}

export const refreshStatusOfAssociatedIdentitySession = async (studentId) => {
  const response = await axios.patch(`/students/${studentId}/identity-session/refresh-status`);
  return response.data;
}

export const createBankSession = async (studentId) => {
  const response = await axios.post(`/students/${studentId}/bank-session`);
  return response.data;
};

export const postBankItem = async (studentId, publicToken, metadata) => {
  await axios.post(`/students/${studentId}/bank-item`, {
    publicToken,
    metadata
  });
  return;
}

export const saveBankMetadata = async (studentId, error, metadata) => {
  await axios.put(`/students/${studentId}/bank-metadata`, {
    error,
    metadata
  });
}

export const updateEmailPreferences = async (email, unsubscribeCode, preferences) => {
  const response = await axios.post('/students/unsubscribe', {
    email,
    unsubscribe_code: unsubscribeCode,
    ...preferences
  });
  return response.data;
};
