import React, { useState } from 'react';
import { Button, Alert, CircularProgress } from '@mui/material';
import { updateLoanApplication } from '../../api/application';
import FortifyIcon from '../FortifyIcon';

import { logErrorToConsole, logErrorToSentryWithContext } from '../../utils/errorLogging';

const Approval = ({ applicationData, updateStage }) => {
  const [serverError, setServerError] = useState('');
  const [isLoading, setIsLoading] = useState(false); 

  const handleAcknowledgeApproval = async () => {
    try {
      setIsLoading(true);
      const response = await updateLoanApplication(applicationData?.id, { loan_approval_acknowledged: true});
      updateStage(response.next_step);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setServerError(error?.response?.data[0] || error?.response?.data?.error || "There was an unexpected error. We're already looking into it, but please try again?");

      logErrorToConsole("Failed to acknowledge loan approval", error);

      if (!error.response || !error.response.status || error.response.status !== 400) {
        logErrorToSentryWithContext(error, {
          applicationId: applicationData.id,
        });
      }
    }
  };

  return (
    <>
      <FortifyIcon />

      <div className='w-full md:w-4/5 lg:w-3/4 text-center'>
        <h1 className="text-2xl sm:text-3xl font-manrope"><b>Congratulations, <br/>you're approved!*</b></h1>

        <h2 className="my-8">Next, you'll just need to verify your identity with Fortify and complete a few last steps to finalize your loan. </h2>

        <div className="my-4">
          <img className="approval-image" src="/celebration.png" alt="Celebration"></img>
        </div>

        {serverError && (
          <div className="mt-4">
            <Alert style={{ maxWidth: '400px', margin: 'auto', textAlign: 'center' }} severity="error">{serverError}</Alert>
          </div>
        )}

        <div className="mt-4">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Button 
              variant="contained" 
              color="primary" 
              className='w-full'
              style={{ fontSize: '1rem' }}
              onClick={handleAcknowledgeApproval}
            >
              Continue
            </Button>
          )}
        </div>

        <div className='mt-4 sm:mt-8 text-left text-legal-gray' style={{ fontSize: '0.75rem' }}>
          <p>*Before you complete your loan by accepting the final terms & signing your promissory note with Fortify, your loan amount and payback terms may change depending on information your school provides to us.</p>
          <br/>
          <p>To continue, we may require you and your school to provide additional information to verify your identity, enrollment status, and loan amount. Your loan cannot be completed and funded if your school does not certify your enrollment, if you or your school do not provide the appropriate information, or if we cannot verify that information to confirm your identity.</p>
        </div>
      </div>
    </>
  );
};

export default Approval;
